<script setup>
import { computed, ref } from 'vue'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useStore } from 'vuex';

const store = useStore();
// const show = ref(true)
const TITLE_MAP = {
  success: 'alert_success',
  error: 'alert_error',
}
const message = computed(() => store.state.message)
const title = computed(() => message.value ? TITLE_MAP[message.value.type] : null)
const isSuccess = computed(() => message.value.type === 'success')
const close = () => store.commit('clearMessage')
</script>

<template>
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:p-6 z-30">
    <div class="flex w-full flex-col items-center space-y-4">
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="-translate-y-5 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div
          v-if="message"
          class="pointer-events-auto w-full max-w-md overflow-hidden rounded-b-xl bg-white shadow-lg border-t-2"
          :class="{
            'border-green-jet': isSuccess,
            'border-red-1000': !isSuccess,
          }"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon v-if="isSuccess" class="h-6 w-6 sm:h-7 sm:w-7 text-green-jet" aria-hidden="true" />
                <ExclamationTriangleIcon v-else class="h-6 w-6 sm:h-7 sm:w-7 text-red-1000" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="text-md sm:text-lg font-medium sm:leading-none mb-1"
                  :class="[{
                    'text-green-jet': isSuccess,
                    'text-red-1000': !isSuccess,
                  }]"
                >
                  {{ $t(title) }}
                </p>
                <p class="mt-1 text-sm sm:text-md text-gray-500" v-html="message.value"/>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button 
                  type="button" 
                  @click="close" 
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>